<template>
  <div ui-view autoscroll="true" class="terms-and-conditions-popup height-100">
      <v-app style="padding: 15px; height: 100%">
          <div class="terms-and-conditions-body height-100">
            <div class="row flex justify-center align-content-center height-100">
              <div class="terms-and-conditions-content text-center height-100">
                <iframe src="/assets/tc.html" title="Terms and conditions" class="height-100" style="border:none;width:100%;"></iframe>
              </div>
            </div>

            <div class="row flex justify-content-center">
              <button class="btn btn btn-primary mt-3 w-auto mr-4" @click="$emit('tcAction', true)">
                I Agree To The Terms & Conditions
              </button>
              <button class="btn btn-cancel btn-grey mt-3 w-auto" @click="$emit('tcAction', false)">
               Close
              </button>
            </div>
          </div>

        </v-app>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions",
  emits: ['tcAction'],
}
</script>

<style scoped>
.terms-and-conditions-popup {
  height: 100vh;
  width: 100%;
  background: #FFF;
  z-index: 10;
  position: fixed;
}

.terms-and-conditions-body{
  padding-top: 20px;
  padding-bottom: 60px;
  height:100%;
}

.terms-and-conditions-content {
  padding: 15px;
  width: 80%;
  text-align: center;
}

.height-100 {
  height: 100%;
}

@media (max-width:1200px){
  .terms-and-conditions-content {
    width: 100%;
  }
}
</style>